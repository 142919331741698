import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >



<path d="M138 8123 c-33 -2 -58 -9 -58 -15 0 -14 138 -4 154 12 7 7 3 9 -14 8
-14 -1 -51 -3 -82 -5z"/>
<path d="M337 8046 c347 -109 614 -258 908 -505 104 -88 278 -260 355 -351 25
-29 57 -66 73 -84 15 -17 85 -107 155 -200 103 -137 128 -164 130 -145 l4 23
19 -25 c10 -13 19 -18 19 -11 0 19 29 14 42 -8 8 -12 24 -20 41 -20 15 0 40
-9 55 -19 19 -14 60 -23 145 -32 64 -7 117 -16 117 -21 0 -4 -5 -8 -11 -8 -5
0 -7 -5 -4 -10 4 -6 12 -4 21 5 17 16 28 20 20 7 -3 -5 8 -16 23 -25 27 -16
29 -16 35 0 4 10 1 20 -9 25 -8 5 -15 13 -15 19 0 11 -41 72 -52 77 -5 2 -8
10 -8 18 0 8 -3 14 -8 14 -4 0 -18 18 -32 40 -14 22 -28 40 -32 40 -5 0 -8 5
-8 10 0 9 -18 32 -65 84 -6 6 -30 36 -55 66 -53 64 -50 61 -165 180 -385 399
-802 660 -1266 795 -140 41 -347 80 -464 89 l-80 7 112 -35z"/>
<path d="M8488 7506 c-123 -35 -228 -108 -281 -192 -94 -151 -67 -368 60 -485
46 -43 143 -103 223 -138 41 -18 94 -41 117 -52 102 -46 146 -119 123 -208
-37 -146 -196 -150 -440 -12 -70 40 -71 40 -79 18 -5 -12 -30 -67 -56 -121
-35 -77 -43 -101 -33 -108 84 -57 246 -115 373 -134 203 -31 371 17 490 140
124 128 161 338 85 492 -43 88 -109 143 -250 210 -215 102 -259 132 -284 195
-37 91 18 159 127 159 61 0 131 -22 226 -69 46 -23 87 -38 91 -34 4 4 32 54
61 111 l54 103 -25 19 c-30 24 -153 74 -235 96 -81 22 -285 28 -347 10z"/>
<path d="M9577 7499 c-91 -26 -133 -48 -194 -104 -98 -87 -141 -208 -122 -340
12 -89 46 -158 106 -218 52 -52 173 -128 254 -158 121 -46 190 -94 209 -144
16 -41 12 -107 -8 -144 -30 -55 -73 -73 -164 -68 -68 3 -89 10 -188 57 -61 30
-116 60 -122 67 -14 17 -24 9 -42 -30 -8 -18 -31 -68 -51 -112 -19 -43 -34
-84 -31 -90 2 -6 38 -28 79 -48 170 -83 347 -118 491 -97 78 11 186 50 214 77
7 7 17 13 21 13 21 0 106 103 138 168 33 66 37 82 41 176 10 221 -61 312 -340
440 -178 82 -238 138 -238 220 0 25 9 45 28 67 24 27 36 32 95 36 76 6 143
-13 259 -75 37 -20 69 -34 72 -31 2 2 28 51 57 109 30 58 55 107 56 110 6 10
-87 59 -162 85 -172 59 -330 71 -458 34z"/>
<path d="M3670 6790 l0 -710 175 0 175 0 0 710 0 710 -175 0 -175 0 0 -710z"/>
<path d="M4290 6790 l0 -710 171 0 172 0 -7 393 c-3 215 -8 412 -11 437 -6 53
-13 60 94 -110 42 -69 166 -259 274 -422 l197 -298 150 0 150 0 0 710 0 710
-174 0 -173 0 4 -332 c3 -183 8 -379 12 -435 3 -57 4 -103 0 -103 -3 0 -43 60
-88 133 -45 72 -172 267 -284 432 l-202 300 -142 3 -143 3 0 -711z"/>
<path d="M5610 7493 c0 -4 97 -200 215 -435 l215 -428 0 -275 0 -275 175 0
175 0 0 267 0 266 86 171 c248 492 354 705 354 710 0 3 -79 6 -175 6 l-175 0
-54 -112 c-29 -62 -88 -185 -131 -272 l-76 -159 -24 49 c-39 81 -121 257 -176
379 l-53 115 -178 0 c-98 0 -178 -3 -178 -7z"/>
<path d="M7221 7463 c-19 -54 -124 -331 -206 -543 -113 -292 -315 -827 -315
-835 0 -3 76 -5 170 -5 l169 0 53 168 54 167 234 0 234 0 50 -167 50 -168 174
0 173 0 -7 23 c-6 21 -59 168 -144 397 -21 58 -68 186 -104 285 -95 264 -254
694 -261 705 -4 6 -72 10 -159 10 l-152 0 -13 -37z m231 -540 c37 -115 69
-216 73 -225 7 -17 -4 -18 -145 -18 -141 0 -152 1 -145 18 4 9 37 110 73 225
36 114 69 207 72 207 3 0 36 -93 72 -207z"/>
<path d="M3050 6920 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3070 6891 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3075 6810 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3118 6803 c6 -2 12 -12 12 -22 0 -9 6 -26 14 -36 7 -10 11 -27 8
-37 -3 -11 3 -25 14 -33 11 -8 14 -15 8 -15 -16 0 -30 20 -39 55 -4 17 -10 24
-12 18 -3 -7 -20 -13 -38 -13 -35 0 -45 -13 -45 -56 0 -21 -4 -24 -40 -24
l-40 0 0 -80 0 -80 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -40 c0 -36 -2 -40
-25 -40 -28 0 -27 10 -30 -191 -2 -119 -12 -166 -27 -119 -4 15 -6 -2 -4 -45
2 -50 -2 -77 -13 -94 -9 -14 -14 -41 -12 -67 2 -35 -1 -44 -13 -44 -12 0 -16
-10 -16 -40 0 -29 -4 -40 -15 -40 -9 0 -15 -9 -15 -24 0 -13 -3 -32 -7 -42 -4
-12 -3 -15 5 -10 8 5 12 0 12 -13 0 -11 -4 -23 -10 -26 -5 -3 -10 -33 -10 -66
0 -52 -2 -59 -20 -59 -17 0 -20 -7 -20 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -15
-10 -34 0 -23 -7 -38 -21 -47 l-21 -14 21 -27 c22 -28 27 -43 11 -33 -6 4 -10
-10 -10 -34 0 -25 -6 -43 -15 -48 -8 -5 -16 -23 -18 -40 -2 -17 -11 -39 -20
-48 -10 -9 -24 -30 -32 -46 -14 -25 -14 -29 -1 -29 8 0 17 10 19 23 2 12 5 -6
6 -40 0 -36 -3 -63 -9 -63 -5 0 -10 -18 -10 -40 0 -33 -3 -40 -20 -40 -17 0
-20 -7 -21 -42 0 -33 -2 -38 -9 -23 -6 13 -9 -14 -9 -77 -1 -58 -5 -98 -11
-98 -5 0 -10 -18 -10 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -22 -4
-40 -10 -40 -6 0 -10 -33 -10 -80 0 -78 -1 -80 -24 -80 -23 0 -24 -4 -31 -80
-4 -44 -11 -80 -16 -80 -5 0 -9 -18 -9 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -18
-10 -40 0 -28 -4 -40 -14 -40 -10 0 -16 -22 -21 -80 -4 -44 -12 -80 -17 -80
-7 0 -9 -20 -6 -52 3 -29 3 -47 1 -40 -10 26 -23 10 -23 -28 0 -29 -4 -40 -15
-40 -27 0 -15 -20 13 -21 l27 -1 -32 -14 c-30 -13 -46 -44 -23 -44 6 0 10 5
10 10 0 6 8 14 18 17 34 13 230 225 335 363 76 99 307 498 307 529 0 5 6 14
14 21 8 6 12 18 9 26 -3 8 0 14 6 14 15 0 14 -37 -1 -43 -10 -4 -10 -6 0 -6
14 -1 18 -131 3 -131 -5 0 -11 8 -13 17 -2 9 -2 -81 0 -200 2 -150 0 -217 -7
-217 -7 0 -11 -30 -11 -79 0 -44 -4 -81 -10 -83 -5 -2 -11 -77 -12 -181 -2
-141 -6 -176 -16 -172 -9 3 -11 -1 -7 -15 4 -11 2 -20 -3 -20 -6 0 -13 -7 -16
-17 -9 -23 67 48 149 140 39 44 77 86 86 95 28 31 168 228 203 288 280 478
401 951 382 1489 -7 202 -19 298 -71 533 -51 234 -84 351 -141 502 -49 130
-122 290 -138 302 -5 3 -11 13 -12 22 -4 25 -45 86 -57 85 -8 0 -8 -2 1 -6z
m-263 -2189 c-8 -20 -15 -45 -15 -55 0 -11 -3 -19 -8 -19 -11 0 -2 82 10 98
21 25 28 11 13 -24z"/>
<path d="M1965 6710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2658 6113 c-2 -27 -1 -64 3 -83 4 -19 7 -26 8 -14 0 12 4 49 7 83 5
40 3 61 -4 61 -5 0 -12 -21 -14 -47z"/>
<path d="M2562 5420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2535 5387 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z"/>
<path d="M2506 5295 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M2466 5175 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2436 5094 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M2460 5103 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M2450 5050 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1202 4973 c-2 -70 -6 -79 -36 -74 -5 1 -109 3 -232 4 l-223 2 -4
-40 c-3 -37 -4 -37 -5 10 -3 80 -21 92 -24 15 -2 -54 -3 -48 -7 35 -9 160 -22
-464 -24 -1193 -2 -613 -1 -642 16 -642 64 0 402 142 515 216 l42 28 -1 671
c-1 581 -12 1182 -17 968z"/>
<path d="M2345 4839 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M5360 4834 c1 -13 29 -48 34 -43 2 2 -4 15 -15 28 -10 13 -19 19 -19
15z"/>
<path d="M8962 4811 c7 -11 18 -21 24 -21 7 0 14 -7 18 -15 3 -8 12 -15 20
-15 9 0 20 -9 26 -19 9 -17 5 -17 -36 5 -51 27 -54 28 -54 14 0 -6 15 -17 33
-25 24 -11 27 -14 12 -15 -12 0 -27 6 -33 12 -10 10 -15 10 -22 -2 -8 -13 -13
-13 -31 -2 -15 9 -24 10 -28 3 -4 -6 -83 -10 -216 -11 -171 -1 -217 1 -247 14
-21 9 -47 16 -58 16 -12 0 -28 7 -36 15 -9 8 -25 15 -37 14 -20 -1 -20 -2 3
-9 20 -7 16 -9 -20 -8 -25 1 -50 -1 -55 -4 -6 -3 -22 -8 -37 -12 -16 -3 -28
-11 -28 -16 0 -6 -90 -10 -240 -10 -197 0 -240 2 -240 14 0 7 -7 19 -14 26
-13 10 -16 3 -19 -47 -3 -54 -3 -53 -7 22 l-3 80 -7 -55 c-5 -30 -8 -263 -9
-517 l-1 -463 408 0 c572 0 711 16 867 98 82 44 210 165 244 232 57 112 66
148 66 280 0 124 0 126 -38 204 -39 82 -96 151 -175 207 -35 25 -41 27 -30 10z
m-567 -83 c143 -46 183 -129 172 -347 -6 -127 -27 -190 -79 -237 -44 -39 -94
-54 -191 -57 l-72 -2 -3 333 -2 332 52 0 c29 0 85 -10 123 -22z m-197 -44 c-3
-27 -4 -26 -9 8 -3 22 -1 34 4 29 5 -5 7 -21 5 -37z"/>
<path d="M4370 4808 c0 -3 25 -22 55 -43 30 -20 55 -41 55 -46 0 -5 5 -9 11
-9 5 0 56 -33 112 -72 56 -40 127 -90 157 -110 64 -44 129 -102 153 -138 12
-17 17 -45 17 -91 0 -61 -3 -72 -33 -111 -21 -27 -55 -54 -92 -73 -54 -28 -66
-30 -154 -28 -61 1 -125 9 -176 22 -98 26 -279 93 -351 131 -29 15 -56 25 -59
22 -3 -3 -5 -88 -5 -188 l0 -183 83 -26 c335 -107 656 -143 842 -95 170 43
319 144 407 272 105 156 149 342 117 500 -27 138 -86 250 -119 228 -12 -7 -12
-12 -2 -22 19 -19 14 -33 -5 -15 -19 16 -73 13 -73 -5 0 -6 -128 -9 -327 -8
-181 0 -358 -1 -395 -3 -38 -2 -68 1 -68 6 0 5 -6 7 -12 5 -7 -3 -19 3 -28 13
-15 17 -110 75 -110 67z m220 -102 c0 -2 -9 -6 -20 -9 -11 -3 -20 -1 -20 4 0
5 9 9 20 9 11 0 20 -2 20 -4z m875 -56 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
<path d="M5738 4741 c-19 -159 -1 -463 34 -566 52 -156 160 -279 310 -353 99
-48 176 -68 335 -83 522 -51 833 142 909 566 9 55 14 146 13 290 -1 162 -3
196 -10 150 -13 -87 -17 -89 -20 -10 -2 66 -2 68 -9 25 l-7 -45 -3 40 c-2 29
-4 33 -7 16 -8 -43 -53 -69 -67 -38 -5 10 -7 10 -12 0 -4 -9 -30 -13 -79 -13
-60 0 -76 3 -84 18 -9 16 -10 16 -16 0 -9 -22 -34 -23 -41 -3 -8 20 -24 19
-26 -2 0 -10 -4 2 -8 27 l-7 45 -3 -60 -3 -60 -7 60 -7 60 -3 -50 -3 -50 -6
50 c-4 30 -10 -47 -14 -195 -9 -288 -17 -336 -57 -379 -59 -62 -102 -76 -230
-76 -104 0 -119 2 -160 25 -94 53 -110 111 -110 390 l0 200 -36 0 c-21 0 -33
4 -29 10 3 6 -3 10 -14 10 -12 0 -21 -4 -21 -10 0 -6 -88 -10 -244 -10 l-245
0 -3 43 c-3 39 -4 37 -10 -22z"/>
<path d="M2776 4375 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M7972 3723 c-4 -10 -7 -24 -6 -30 1 -7 2 -20 3 -28 0 -8 4 -14 9 -14
4 1 476 1 1050 0 l1042 -1 0 -434 c0 -239 -4 -437 -9 -440 -4 -3 -769 -6
-1700 -6 -1304 -1 -1691 -4 -1691 -13 0 -17 11 -31 40 -50 23 -16 142 -17
1693 -17 1836 0 1713 -4 1736 61 7 22 11 177 11 478 0 525 7 491 -107 502 -37
4 -517 7 -1065 8 -942 1 -998 0 -1006 -16z"/>
<path d="M2028 3473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3740 3421 c-30 -3 -54 -9 -52 -13 1 -5 2 -89 2 -188 l0 -180 40 0
40 0 0 74 0 74 48 7 c87 13 122 48 122 124 0 46 -35 86 -83 96 -56 11 -61 12
-117 6z m90 -71 c38 -38 14 -105 -34 -98 -17 2 -22 11 -24 47 -2 24 -1 50 2
57 8 20 33 17 56 -6z"/>
<path d="M4082 3417 c-89 -28 -140 -171 -97 -274 22 -51 40 -70 91 -89 86 -33
184 9 219 94 18 42 19 141 3 179 -36 85 -121 121 -216 90z m118 -92 c21 -42
19 -155 -3 -189 -13 -20 -26 -26 -52 -26 -61 0 -80 37 -73 149 4 85 17 103 72
99 34 -2 43 -8 56 -33z"/>
<path d="M5213 3423 l-53 -4 0 -190 0 -189 45 0 45 0 0 85 c0 48 4 85 10 85
18 0 48 -41 75 -106 l27 -64 44 0 c24 0 44 4 44 9 0 18 -33 87 -62 130 l-29
44 25 26 c19 20 26 38 26 68 0 57 -28 89 -91 102 -27 6 -51 10 -52 9 -1 -1
-26 -3 -54 -5z m99 -76 c15 -23 8 -66 -11 -77 -42 -22 -51 -14 -51 46 l0 56
27 -7 c15 -3 31 -12 35 -18z"/>
<path d="M5843 3423 l-53 -4 0 -187 0 -187 78 1 c93 1 140 15 175 52 34 36 47
72 47 133 0 140 -82 204 -247 192z m122 -83 c39 -43 36 -179 -5 -212 -12 -10
-35 -18 -51 -18 l-29 0 0 125 0 125 33 0 c21 0 41 -8 52 -20z"/>
<path d="M6333 3422 l-53 -3 0 -189 0 -188 83 3 c131 5 177 33 177 109 0 27
-7 40 -35 63 l-35 29 30 22 c58 43 29 133 -47 146 -21 4 -45 8 -53 10 -8 1
-39 0 -67 -2z m99 -75 c4 -7 8 -21 8 -32 0 -29 -12 -43 -43 -50 l-27 -7 0 57
0 57 27 -7 c15 -3 31 -12 35 -18z m2 -153 c9 -8 16 -28 16 -44 0 -22 -6 -30
-31 -39 -48 -16 -49 -15 -49 44 0 52 2 55 24 55 14 0 32 -7 40 -16z"/>
<path d="M7103 3423 l-53 -4 0 -187 0 -187 88 1 c93 1 129 13 169 56 64 69 56
217 -15 281 -24 22 -89 47 -117 45 -11 -1 -44 -3 -72 -5z m126 -89 c17 -22 21
-41 21 -101 0 -65 -3 -77 -25 -98 -15 -16 -36 -25 -55 -25 l-30 0 0 125 0 125
34 0 c25 0 40 -7 55 -26z"/>
<path d="M7823 3423 l-53 -4 0 -190 0 -191 84 6 c47 3 100 12 120 20 77 32 87
118 19 159 -33 19 -34 22 -16 30 11 5 28 22 37 39 17 29 17 32 1 69 -16 34
-25 41 -68 54 -28 8 -55 14 -61 13 -6 -1 -35 -3 -63 -5z m81 -63 c21 -8 26
-16 26 -45 0 -29 -5 -37 -26 -45 -41 -16 -44 -13 -44 45 0 58 3 61 44 45z m17
-160 c13 -7 19 -21 19 -44 0 -29 -5 -36 -31 -45 -17 -6 -35 -8 -40 -5 -5 3 -9
27 -9 55 0 43 3 49 21 49 11 0 29 -5 40 -10z"/>
<path d="M8793 3423 l-53 -4 0 -187 0 -188 78 2 c108 2 134 10 171 48 69 71
68 209 -1 284 -20 21 -108 54 -132 50 -6 -1 -35 -3 -63 -5z m123 -84 c16 -17
19 -37 19 -106 0 -77 -2 -87 -24 -104 -13 -10 -36 -19 -52 -19 l-29 0 0 125 0
125 33 0 c22 0 41 -8 53 -21z"/>
<path d="M4338 3415 c-3 -3 18 -87 46 -188 l52 -182 34 0 34 0 32 100 c18 55
33 110 34 123 1 46 16 19 43 -79 42 -151 41 -149 81 -149 31 0 35 3 41 33 4
17 19 75 35 127 15 52 33 115 39 140 7 25 15 53 17 63 5 14 -2 17 -40 17 l-44
0 -28 -117 c-21 -93 -29 -113 -35 -93 -4 14 -20 67 -35 118 -27 92 -27 92 -60
92 -41 0 -44 -5 -80 -140 -16 -58 -30 -96 -32 -85 -2 11 -14 65 -28 119 -13
54 -24 101 -24 102 0 6 -76 5 -82 -1z"/>
<path d="M4870 3230 l0 -190 120 0 120 0 0 35 0 35 -75 0 -75 0 0 50 0 50 54
0 c47 0 54 3 60 23 3 12 6 25 6 30 0 4 -27 7 -60 7 l-60 0 0 45 0 45 69 0 c71
0 83 7 73 43 -4 15 -18 17 -118 17 l-114 0 0 -190z"/>
<path d="M5500 3230 l0 -191 120 2 120 1 0 34 0 33 -77 3 -78 3 -3 48 -3 47
60 0 61 0 0 33 c0 32 0 32 -45 29 -66 -6 -77 8 -66 81 0 4 33 7 71 7 l70 0 0
30 0 30 -115 0 -115 0 0 -190z"/>
<path d="M6560 3415 c0 -3 25 -55 55 -115 52 -104 55 -114 55 -185 l0 -75 45
0 45 0 0 75 c0 71 3 80 58 190 l59 115 -46 0 -46 0 -34 -73 -34 -72 -34 73
-35 72 -44 0 c-24 0 -44 -2 -44 -5z"/>
<path d="M7400 3284 c0 -163 11 -196 74 -225 51 -23 94 -24 146 -3 73 29 84
58 88 222 l4 142 -46 0 -46 0 0 -125 c0 -71 -5 -135 -11 -149 -14 -29 -48 -40
-83 -26 -34 13 -46 61 -46 193 l0 107 -40 0 -40 0 0 -136z"/>
<path d="M8188 3383 c-8 -21 -29 -74 -46 -118 -16 -44 -42 -110 -56 -148 -14
-37 -26 -70 -26 -73 0 -3 19 -4 42 -2 40 3 43 5 59 48 l17 45 63 0 63 0 14
-47 13 -48 45 0 c53 0 57 -24 -31 210 l-62 165 -39 3 c-37 3 -41 1 -56 -35z
m71 -110 c8 -27 16 -54 19 -60 3 -9 -8 -13 -37 -13 -23 0 -41 3 -41 7 0 14 35
113 40 113 3 0 12 -21 19 -47z"/>
<path d="M8445 3408 c-3 -8 -4 -92 -3 -188 l3 -175 45 0 45 0 0 185 0 185 -43
3 c-28 2 -44 -1 -47 -10z"/>
<path d="M9146 3343 c-16 -43 -47 -122 -68 -176 -21 -53 -38 -104 -38 -112 0
-11 11 -15 39 -15 38 0 40 1 56 50 l17 50 61 0 62 0 15 -48 c15 -46 16 -47 58
-50 49 -4 49 -6 12 93 -10 28 -38 103 -62 168 l-43 117 -40 0 -40 0 -29 -77z
m88 -77 c9 -30 16 -57 16 -60 0 -3 -18 -6 -41 -6 -35 0 -40 3 -34 18 4 9 12
33 17 52 19 65 22 65 42 -4z"/>
<path d="M9357 3413 c-4 -3 -7 -17 -7 -30 0 -21 5 -23 45 -23 l44 0 3 -157 3
-158 41 -3 42 -3 6 45 c4 25 6 96 5 158 l-2 113 39 3 c40 4 54 15 54 45 0 15
-13 17 -133 17 -74 0 -137 -3 -140 -7z"/>
<path d="M9737 3413 c-8 -13 -137 -359 -137 -367 0 -4 19 -6 41 -4 40 3 42 5
57 51 l15 47 63 0 63 0 13 -47 c13 -47 15 -48 56 -51 30 -2 42 1 42 10 0 7
-23 74 -50 148 -28 74 -57 154 -66 178 -15 39 -18 42 -54 42 -21 0 -41 -3 -43
-7z m70 -184 c5 -28 4 -29 -31 -29 -42 0 -43 6 -19 76 l17 49 13 -34 c8 -18
17 -46 20 -62z"/>
<path d="M7050 2909 c-217 -3 -996 -12 -1730 -19 -734 -7 -1350 -13 -1367 -14
-20 0 -33 -5 -33 -13 0 -10 38 -14 163 -15 89 -2 -2 -5 -203 -8 -223 -3 52 -6
710 -8 1061 -2 3009 12 3248 24 l122 6 0 29 0 29 -257 -2 c-142 -1 -436 -5
-653 -9z m-2827 -46 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
